<template lang="pug">
  transition(name='fade')
    b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(no-body)
      b-card-body.d-flex.justify-content-start.align-items-center
</template>

<script>
import { Panel } from '@common/panel'

export default {
  name: 'lb-dashboard-panel-machines',
  mixins: [Panel],
  props: {
    data: Object,
    show: Boolean,
  },
  watch: {
    panel() {
      this.$emit('update:data', this.panel)
    },
    panelShow() {
      this.$emit('update:show', this.panelShow)
    },
  },
  data() {
    return {
      cacheType: 'dashboard/getPanelMachines',
      skipNotifications: true,
    }
  },
}
</script>
