<template lang="pug">
  main#main.dashboard-show
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-tachometer')
      section.l-section
        .l-section-h
          b-row(cols=1 cols-md=2 cols-xl=3)
            b-col.mb-3.pb-3.mb-md-4.pb-md-4
              panel-current-model(
                ref='panelCurrentModel'
                :is-organization="$store.getters['auth/isOrganization']")

            //-b-col.mb-3.pb-3.mb-md-4.pb-md-4
              panel-organizations(ref='panelOrganizations')

            //-b-col.mb-3.pb-3.mb-md-4.pb-md-4
              panel-machines(ref='panelMachines')
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import PanelCurrentModel from '@components/layouts/shared/PanelCurrentModel'
import PanelOrganizations from '@components/layouts/base/dashboard/PanelOrganizations'
import PanelMachines from '@components/layouts/base/dashboard/PanelMachines'

export default {
  name: 'dashboard-show',
  components: {
    WcBreadcrumb,
    PanelCurrentModel,
    PanelOrganizations,
    PanelMachines,
  },
}
</script>
